<template>
  <div class="table">
    <div class="table__header">
      <div class="flex items-center">
        <h1 class="title-h1">Сотрудники</h1>

        <div class="ml-auto">
          <BmSelect
            :option="currentUnit"
            :options="allUnits"
            label="Юнит"
            @update:option="setUnit"
          >
            <template #active-label="{value}">
              <div class="select__value pl-2">{{ value.name }}</div>
            </template>
            <template #option="{option}">
              <div class="select__value">{{ option.name }}</div>
            </template>
          </BmSelect>
        </div>

        <div class="ml-8">
          <BmSelect
            :option="filterWeeks"
            :options="getWeeks()"
            :reset="false"
            @update:option="setFilterWeeks"
          >
            <template #active-label="{value}">
              <div class="select__value pl-2">{{ value.name }}</div>
            </template>
            <template #option="{option}">
              <div class="select__value">{{ option.name }}</div>
            </template>
          </BmSelect>
        </div>
      </div>
      <div class="table__thead">
        <div class="table__tr">
          <div class="table__th pr-8">
            <div class="table__th-content">
              <div style="width: 50px"></div>
              <div style="width: 180px">
                <div
                  :class="
                    sortDirection ? 'table__th-sortable_' + sortDirection : ''
                  "
                  class="table__th-label table__th-sortable"
                  @click="setSortDirection"
                >
                  Имя
                </div>
              </div>
              <div class="table__th-label">Должность</div>
              <div class="table__th-week ml-auto">
                <div
                  v-for="date in datesRange"
                  style="width: 80px"
                  :class="{
                    active: date.format('YYYY-MM-DD') === filterWeeks.id,
                  }"
                >
                  <div
                    class="table__th-label text-right text-grey-light"
                    style="text-transform: capitalize"
                  >
                    {{ monthLabel(date) }}
                  </div>
                  <div class="table__th-value text-right">
                    {{ weekLabel(date, true) }}
                  </div>
                </div>
                <div @click="incrementWeek(-1)">
                  <BmIconChevronLeft class="table__th-prev" />
                </div>
                <div @click="incrementWeek(1)">
                  <BmIconChevronRight class="table__th-next" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <skeleton-table v-if="false" :rows-count="currentWorkload.length" />
    <template v-else>
      <overlay-scrollbars>
        <div class="table__tbody">
          <div
            v-for="(user, i) in currentWorkload"
            :key="user.id"
            class="table__tr table__tr_body"
            @click="expandRow(i)"
          >
            <div
              :class="{table__td_active: user.expand}"
              class="table__td pr-8"
            >
              <div
                :class="{'table__td-content_active': user.expand}"
                class="table__td-content"
              >
                <div style="width: 50px">
                  <BmIconChevronRight
                    :class="{'table__expand-icon_expanded': user.expand}"
                    class="table__expand-icon"
                    color="black"
                  />
                </div>
                <div style="width: 180px" class="table__td-label">
                  {{ user.name }}
                </div>
                <div class="table__td-label">
                  <BmBadge
                    v-if="user.positions"
                    :label="user.positions"
                    uppercase
                  />
                </div>
                <div class="flex items-center ml-auto no-wrap">
                  <div
                    v-for="(week, i) in user.weeks"
                    :key="'uw' + i"
                    :class="{
                      'text-grey-light': week.duration === 0,
                      'text-red': week.duration / 3600 > 40,
                    }"
                    style="width: 80px"
                    class="text-right pl-md"
                  >
                    {{ week.duration / 3600 }} ч
                  </div>
                </div>
              </div>
              <div
                :class="{table__expansion_active: user.expand}"
                class="table__expansion"
              >
                <div
                  v-for="project in user.projects"
                  :key="project.id"
                  class="table__td-content no-border"
                >
                  <div style="width: 50px"></div>
                  <div class="flex items-center">
                    <div class="text-regular">{{ project.name }}</div>
                  </div>
                  <div class="flex items-center ml-auto">
                    <div v-for="date in datesRange">
                      <div
                        v-for="scheduledWeek in project.scheduledWeeks"
                        style="width: 80px"
                      >
                        <template
                          v-if="
                            date.format('YYYYWW') ===
                            scheduledWeek.weekNumber.toString()
                          "
                        >
                          <div
                            :key="user.id + date.format('YYYY-MM-DD')"
                            :class="{
                              'text-grey-light':
                                scheduledWeek.duration / 3600 === 0,
                              'text-red': scheduledWeek.duration / 3600 > 40,
                            }"
                            class="text-right"
                          >
                            {{ scheduledWeek.duration / 3600 }} ч
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </overlay-scrollbars>
    </template>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import {weeksRange, weekLabel, getWeeks} from '@src/utils/helpers'
import OverlayScrollbars from '@src/components/overlay-scrollbars/OverlayScrollbars'
import SkeletonTable from './SkeletonUsersTable'

export default {
  name: 'EmployeesListTable',
  components: {
    OverlayScrollbars,
    SkeletonTable,
  },
  data() {
    return {
      datesRange: [],
      currentWorkload: [],
      currentUnit: null,
      sortDirection: null,
    }
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
      allUnits: (state) => state.units.allUnits,
      workload: (state) => state.users.workload,
      filterWeeks: (state) => state.users.filterWeeks,
    }),
  },
  methods: {
    ...mapMutations({
      setFilterWeeks: 'users/setFilterWeeks',
    }),
    ...mapActions({
      fetchWorkload: 'users/fetchWorkload',
      fetchUnits: 'units/fetchUnits',
    }),
    weeksRange,
    weekLabel,
    getWeeks,
    incrementWeek(increment) {
      const date = this.$moment(this.filterWeeks.id, 'YYYY-MM-DD').add(
        increment,
        'w'
      )

      this.setFilterWeeks({
        id: date.format('YYYY-MM-DD'),
        name: this.weekLabel(date),
      })
    },
    setUnit(unit) {
      this.currentUnit = unit
    },
    expandRow(index) {
      const row = {
        ...this.currentWorkload[index],
        expand: !this.currentWorkload[index].expand,
      }
      this.$set(this.currentWorkload, index, row)
    },
    setSortDirection() {
      this.sortDirection = !this.sortDirection
        ? 'asc'
        : this.sortDirection === 'asc'
        ? 'desc'
        : null
    },
    monthLabel(date) {
      return date.format('MMM').substring(0, 3)
    },
    filterByUnit() {
      if (!this.currentUnit) {
        this.currentWorkload = [...this.workload]
        return
      }

      this.currentWorkload = this.workload.filter((user) =>
        user.units?.includes(this.currentUnit.name)
      )
    },
  },
  watch: {
    filterWeeks() {
      this.datesRange = this.weeksRange(this.filterWeeks.id)

      this.fetchWorkload({
        startDate: this.datesRange[0].format('YYYY-MM-DD'),
        endDate: this.datesRange
          .slice(-1)[0]
          .add(6, 'days')
          .format('YYYY-MM-DD'),
      })
    },
    allUnits() {},
    workload: {
      immediate: true,
      deep: true,
      handler() {
        this.currentWorkload = [...this.workload]
        this.filterByUnit()
      },
    },
    currentUser() {
      const currentUserUnit = this.lodash.head(this.currentUser.units)
      if (this.currentUnit === null && currentUserUnit) {
        this.setUnit(currentUserUnit)
      }
    },
    currentUnit() {
      this.filterByUnit()
    },
    sortDirection() {
      if (this.sortDirection === 'asc') {
        this.currentWorkload.sort((a, b) => a.name.localeCompare(b.name))
      } else if (this.sortDirection === 'desc') {
        this.currentWorkload.sort(() => -1)
      } else {
        this.currentWorkload = [...this.rows]
      }
    },
  },
  created() {
    const date =
      this.filterWeeks?.id ?? this.$moment().weekday(0).format('YYYY-MM-DD')

    this.setFilterWeeks({
      id: date,
      name: this.weekLabel(date),
    })

    Promise.all([this.fetchUnits()])
  },
}
</script>
