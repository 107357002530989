var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('div',{staticClass:"table__header"},[_c('div',{staticClass:"flex items-center"},[_c('h1',{staticClass:"title-h1"},[_vm._v("Сотрудники")]),_c('div',{staticClass:"ml-auto"},[_c('BmSelect',{attrs:{"option":_vm.currentUnit,"options":_vm.allUnits,"label":"Юнит"},on:{"update:option":_vm.setUnit},scopedSlots:_vm._u([{key:"active-label",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"select__value pl-2"},[_vm._v(_vm._s(value.name))])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"select__value"},[_vm._v(_vm._s(option.name))])]}}])})],1),_c('div',{staticClass:"ml-8"},[_c('BmSelect',{attrs:{"option":_vm.filterWeeks,"options":_vm.getWeeks(),"reset":false},on:{"update:option":_vm.setFilterWeeks},scopedSlots:_vm._u([{key:"active-label",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"select__value pl-2"},[_vm._v(_vm._s(value.name))])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"select__value"},[_vm._v(_vm._s(option.name))])]}}])})],1)]),_c('div',{staticClass:"table__thead"},[_c('div',{staticClass:"table__tr"},[_c('div',{staticClass:"table__th pr-8"},[_c('div',{staticClass:"table__th-content"},[_c('div',{staticStyle:{"width":"50px"}}),_c('div',{staticStyle:{"width":"180px"}},[_c('div',{staticClass:"table__th-label table__th-sortable",class:_vm.sortDirection ? 'table__th-sortable_' + _vm.sortDirection : '',on:{"click":_vm.setSortDirection}},[_vm._v(" Имя ")])]),_c('div',{staticClass:"table__th-label"},[_vm._v("Должность")]),_c('div',{staticClass:"table__th-week ml-auto"},[_vm._l((_vm.datesRange),function(date){return _c('div',{class:{
                  active: date.format('YYYY-MM-DD') === _vm.filterWeeks.id,
                },staticStyle:{"width":"80px"}},[_c('div',{staticClass:"table__th-label text-right text-grey-light",staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(_vm.monthLabel(date))+" ")]),_c('div',{staticClass:"table__th-value text-right"},[_vm._v(" "+_vm._s(_vm.weekLabel(date, true))+" ")])])}),_c('div',{on:{"click":function($event){return _vm.incrementWeek(-1)}}},[_c('BmIconChevronLeft',{staticClass:"table__th-prev"})],1),_c('div',{on:{"click":function($event){return _vm.incrementWeek(1)}}},[_c('BmIconChevronRight',{staticClass:"table__th-next"})],1)],2)])])])])]),(false)?_c('skeleton-table',{attrs:{"rows-count":_vm.currentWorkload.length}}):[_c('overlay-scrollbars',[_c('div',{staticClass:"table__tbody"},_vm._l((_vm.currentWorkload),function(user,i){return _c('div',{key:user.id,staticClass:"table__tr table__tr_body",on:{"click":function($event){return _vm.expandRow(i)}}},[_c('div',{staticClass:"table__td pr-8",class:{table__td_active: user.expand}},[_c('div',{staticClass:"table__td-content",class:{'table__td-content_active': user.expand}},[_c('div',{staticStyle:{"width":"50px"}},[_c('BmIconChevronRight',{staticClass:"table__expand-icon",class:{'table__expand-icon_expanded': user.expand},attrs:{"color":"black"}})],1),_c('div',{staticClass:"table__td-label",staticStyle:{"width":"180px"}},[_vm._v(" "+_vm._s(user.name)+" ")]),_c('div',{staticClass:"table__td-label"},[(user.positions)?_c('BmBadge',{attrs:{"label":user.positions,"uppercase":""}}):_vm._e()],1),_c('div',{staticClass:"flex items-center ml-auto no-wrap"},_vm._l((user.weeks),function(week,i){return _c('div',{key:'uw' + i,staticClass:"text-right pl-md",class:{
                    'text-grey-light': week.duration === 0,
                    'text-red': week.duration / 3600 > 40,
                  },staticStyle:{"width":"80px"}},[_vm._v(" "+_vm._s(week.duration / 3600)+" ч ")])}),0)]),_c('div',{staticClass:"table__expansion",class:{table__expansion_active: user.expand}},_vm._l((user.projects),function(project){return _c('div',{key:project.id,staticClass:"table__td-content no-border"},[_c('div',{staticStyle:{"width":"50px"}}),_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"text-regular"},[_vm._v(_vm._s(project.name))])]),_c('div',{staticClass:"flex items-center ml-auto"},_vm._l((_vm.datesRange),function(date){return _c('div',_vm._l((project.scheduledWeeks),function(scheduledWeek){return _c('div',{staticStyle:{"width":"80px"}},[(
                          date.format('YYYYWW') ===
                          scheduledWeek.weekNumber.toString()
                        )?[_c('div',{key:user.id + date.format('YYYY-MM-DD'),staticClass:"text-right",class:{
                            'text-grey-light':
                              scheduledWeek.duration / 3600 === 0,
                            'text-red': scheduledWeek.duration / 3600 > 40,
                          }},[_vm._v(" "+_vm._s(scheduledWeek.duration / 3600)+" ч ")])]:_vm._e()],2)}),0)}),0)])}),0)])])}),0)])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }