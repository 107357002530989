<template>
  <div>
    <div class="content mt-8">
      <employees-list-table />
    </div>
  </div>
</template>

<script>
import EmployeesListTable from './_components/UsersTable'

export default {
  name: 'EmployeesPage',
  components: {
    EmployeesListTable,
  },
}
</script>
